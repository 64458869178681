import Sidebar from './SideBar.vue';
import SidebarItem from './SidebarItem.vue';
import { reactive } from 'vue';

const SidebarPlugin = {
  install(app, options) {
    const SidebarStore = {
      showSidebar: false,
      sidebarLinks: [],
      isMinimized: false,
      breakpoint: 1200,
      hovered: false,
      displaySidebar(value) {
        if (window.innerWidth > this.breakpoint) {
          return;
        }
        this.isMinimized = value
        this.showSidebar = value;
        let docClasses = document.body.classList
        if (value) {
          docClasses.add('g-sidenav-pinned')
          docClasses.add('g-sidenav-show')
          docClasses.remove('g-sidenav-hidden')
        } else {
          docClasses.add('g-sidenav-hidden')
          docClasses.remove('g-sidenav-pinned')
          docClasses.remove('g-sidenav-show')
        }
      },
      minimize(value=true){
        this.isMinimized = value
        const docClasses = document.body.classList
        docClasses.add('g-sidenav-hidden')
        docClasses.remove('g-sidenav-pinned')
        docClasses.remove('g-toggle-hover')
        docClasses.remove('g-sidenav-show')
      },
      toggleMinimize() {
        this.isMinimized = !this.isMinimized;
        if (this.isMinimized) {
          // expand fixed div.
          this.minimize(this.isMinimized)
        } else {
          const docClasses = document.body.classList
          // collapse fixed div.
          docClasses.add('g-sidenav-pinned')
          docClasses.add('g-sidenav-show')
          docClasses.add('g-toggle-hover')
          docClasses.remove('g-sidenav-hidden')
        }
        // Dispatch event for userInput on Toggle
        const event = new CustomEvent('toggleUsed', {
          detail: this.isMinimized
        });
        window.dispatchEvent(event)
        if (this.hovered) {
          // docClasses.add('g-sidenav-show')
        }
      },
      onMouseEnter() {
        this.hovered = true
        if (this.isMinimized) {
          document.body.classList.add('g-toggle-hover')
          document.body.classList.add('g-sidenav-show')
          document.body.classList.remove('g-sidenav-hidden')
        } else {
          document.body.classList.remove('g-toggle-hover')
        }
      },
      onMouseLeave() {
        this.hovered = false
        if (this.isMinimized) {
          let docClasses = document.body.classList
          docClasses.remove('g-sidenav-show')
          docClasses.add('g-sidenav-hide')
          setTimeout(() => {
            docClasses.remove('g-sidenav-hide')
            docClasses.add('g-sidenav-hidden')
          }, 300)
        }
      }
    };
    

    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks;
    }

    app.config.globalProperties.$sidebar = SidebarStore;

    app.component('side-bar', Sidebar);
    app.component('sidebar-item', SidebarItem);
  }
};

export default SidebarPlugin;
