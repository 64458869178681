<template>
  <vee-field :rules="rules" type="file" v-on="listeners" :name="name" v-slot="{meta: {errors, valid, dirty, touched}, field}" ref="validation_provider" as=span>
  <div class="form-group" :class="formGroupClasses">
    <label for="" class="form-control-label" :class="{'is_required':labelRequired}" v-if="label">{{ label }}</label> <small  v-if="labelInfo">{{ labelInfo }}</small>
  <div class="custom-file" v-if="!displayImage">
    <input type="file"
           class="custom-file-input"
           :class="{'is-invalid':!valid && touched,'is-valid': files.length > 0 && touched && valid && successMessage}"
           lang="en"
           v-bind="{...field, ...$attrs}"
           ref="file_input"
    />
    <label class="custom-file-label" for="customFileLang">
      {{selectedLabel}}
    </label>
    <small v-if="info" class="font-small">{{ info }}</small>
  </div>
  <div v-else class="display-image" v-loading="!showCloseImage">
    <el-image :src="uploadedImage" lazy fit="contain" class="image" @load="imageLoaded" @error="imageError" :class="uploadedImageCss"/>
    <img src="/img/icons/buttons/remove-no-bg.svg" class="close-image" alt="cross" v-if="showCloseImage" @click="closeImage">
  </div>
  <div v-if="errors && errors[0]" class="invalid-feedback" style="display: block;">
        {{ errors[0] }}
  </div>
  </div>
</vee-field>
</template>
<script>
  export default {
    name: 'file-input',
    inheritAttrs: false,
    props: {
      label:{
        type:String,
        default:""
      },
      labelInfo:{
        type:String,
        default:""
      },
      uploadedImage: {
        type: String,
        default: ""
      },
      uploadedImageCss: {
        type: String,
        default: ""
      },
      info:{
        type:String,
        default:""
      },
      initialLabel: {
        type: String,
        default: 'No file chosen'
      },
      name:{
        type: String,
        default: "image"
      },
      rules: {
        type: [String, Array, Object],
        description: 'Vee validate validation rules',
        default: ''
      },
      successMessage: {
        type: String,
        description: "Input success message",
        default: ''
      },
      labelRequired:{
        type: Boolean,
        default: false
      },
      formGroupClasses: {
        type: [String, Array],
        default: 'mb-4'
      }
    },
    data() {
      return {
        files: [],
        displayImage: false,
        showCloseImage: false
      }
    },
    mounted(){
      if(this.uploadedImage){
        this.displayImage = true;
      }
      this.$refs.validation_provider.reset();
    },
    watch:{
      uploadedImage(newVal){
        if(newVal){
          this.displayImage = true;
        }
      }
    },
    computed: {
      listeners() {
        return {
          change: this.fileChange
        }
      },
      selectedLabel() {
        let fileNames = [];
        for (let file of this.files) {
          fileNames.push(file.name)
        }
        return fileNames.length ? fileNames.join(', ') : this.initialLabel
      }
    },
    methods: {
      async fileChange(evt) {
        this.files = evt.target.files;
        await this.$refs.validation_provider.validate(evt);
        this.$emit('change', this.files);
        
      },
      clearFile(){
        this.files = [];
        if(!this.$refs.file_input)return;
        this.$refs.file_input.value = null;
        // Manually trigger the change event to update validation
        const inputEvent = new Event('change');
        this.$refs.file_input.dispatchEvent(inputEvent);
      },
      closeImage(){
        this.displayImage = false;
        this.showCloseImage = false;
        this.$emit('closeImage');
      },
      imageLoaded(){
        this.showCloseImage = true;
      },
      imageError(){
        this.showCloseImage = true;
      }
    }
  }
</script>
<style lang="scss">
.is_required::after{
  content: "*";
  color: red;
}
.display-image{
  min-height: 46px;
  border: 1px solid #dee2e6;
  padding: 0.50rem;
  display: flex;
  justify-content: start;
  align-items: center;
  .image{
    max-width: 80%;
    margin-right: 0.5rem;
  }
  .close-image{
    cursor: pointer;
  }
}
</style>
