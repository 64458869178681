// src/filters.js

import moment from 'moment';

export const globalFilters = {
  globalDateFormat(date) {
    return moment(date).format('MMM D, YYYY');
  },
  fullMonthWithYear(date) {
    return moment(date).format('MMMM YYYY');
  },
  fullMonthName(date) {
    return moment(date).format('MMMM');
  },
  fullYearName(date) {
    return moment(date).format('YYYY');
  },
  time(date) {
    return moment.utc(date).local().format('hh:mma');
  },
};
