import { defineRule } from "vee-validate";
import { ext, required, confirmed, email } from "@vee-validate/rules";
import moment from "moment";

defineRule("support_email", (value, target, ctx) => {
  if (!value) return true;
  if(!email(value)) {
    return `The email field must have a valid email address.`
  }
  return true;
});

defineRule("custom_confirmed", (value, target, ctx) => {
  if (!value) return true;
  let field = ctx.field;
  let name = `${field.substring(0, 1).toUpperCase()}${field
    .substring(1)
    .replaceAll("_", " ")}`;
   if(!confirmed(value, target)) {
    return `${name} doesn't match.`
  }
  return true;
});

defineRule("custom_required", (value, [target], ctx) => {
  let field = ctx.field;
  let name = `${field.substring(0, 1).toUpperCase()}${field
    .substring(1)
    .replaceAll("_", " ")}`;
    if (!required(value)) {
      return `${name} is required.`;
    } else {
      return true;
    }
});

defineRule("custom_url", (value, [target], ctx) => {
  if (
    !(
      value &&
      /^((http|https|ftp):\/\/)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}(\/\S*)?$/.test(
        value,
      )
    )
  ) {
    let field = ctx.field;
    if (field.toLowerCase() == "url") {
      return `Please enter valid URL.`;
    }
    return `Please enter valid ${field}.`;
  } else {
    return true;
  }
});
defineRule("extensions", (value, params, ctx) => {
  let field = ctx.field;
  if (value) {
    let typesArray = [];
    for (let index in params) {
      if (!isNaN(index)) {
        typesArray.push(params[index].toUpperCase());
      }
    }
    let typesMessage =
      typesArray.splice(0, typesArray.length - 1).join(",") +
      " and " +
      typesArray[typesArray.length - 1];
    return (
      ext(value, params) || `The ${field} must be ${typesMessage}.`
    );
  }
  return true;
});

defineRule("validate_start_time", (value, [target], ctx) => {
  if (target[0] != "" && target[1] != "") {
    return validateTime(target[0], target[1]) || "Start Time must be less than End Time";
  }
});

defineRule("validate_end_time", (value, [target], ctx) => {
  if (target[0] != "" && target[1] != "") {
    return validateTime(target[0], target[1]) || "End Time must be less than Start Time";
  }
});


function validateTime(start, end) {
  let startTime = moment(start, "hh:mm A");
  let endTime = moment(end, "hh:mm A");
  return startTime < endTime;
}

defineRule("min_dimensions", async (value, target, ctx) => {
  let min_width = target[0] || 0;
  let min_height = target[1] || 0;
  let ratio = target[2] || 0;
  let validate = true;
  if (value) {
    if (value && Array.isArray(value)) {
      for (let file of value) {
        validate = await calculateMinDimensions(
          file,
          min_width,
          min_height,
          ratio,
        );
      }
    } else if (value && !Array.isArray(value)) {
      validate = await calculateMinDimensions(
        value,
        min_width,
        min_height,
        ratio,
      );
    }
  }
  if (!validate) {
    let field= ctx.field;
    return `Please upload ${field} with minimum ${min_width} * ${min_height}.`;
  }
  return true;
});

function calculateMinDimensions(file, min_width, min_height, ratio) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    //Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      let image = new Image();
      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        let height = this.height;
        let width = this.width;
        if (
          height < min_height ||
          width < min_width ||
          (ratio > 0 && (width / height).toFixed(1) != ratio)
        ) {
          resolve(false);
        }
        resolve(true);
      };
    };
  });
}

