import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import authRoutes from './authRoutes';
import adminRoutes from './adminRoutes';
import authHelper from '../util/authHelper';
import { store } from '@/vuex/store'


// configure router
const router = createRouter({
  history: createWebHistory(),
  routes: [...adminRoutes, ...routes, ...authRoutes], // spreading the routes arrays
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { left: 0, top: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  let authUser = store.getters.getAuthUser;

  if (!authUser && authHelper.getAuthToken('subUser')) {
    try {
      await store.dispatch('_getSubUser');
      authUser = store.getters.getAuthUser;
      window.localStorage.setItem('customizePipeline', authUser.administrative_rights ?? 0);
      store.commit('toggleTermsAndConditionsModal', !!(authUser.self_service && !authUser.terms_and_conditions_check));
    } catch (error) {
      authHelper.deleteAuthToken('subUser');
      authHelper.deleteRefreshToken('subUser');
      next({ name: 'Login' });
      return;
    }
  }

  const isAuthenticated = !!authUser;

  const require_auth = to.matched.some((el) => el.meta.auth) || false;
  const require_super_admin = to.matched.some((el) => el.meta.super_admin) || false;

  if (require_auth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (require_auth && isAuthenticated) {
    if (authUser.type === 'super_admin' && !require_super_admin) {
      next({ name: 'admin.dashboard' });
    } else if (authUser.type !== 'super_admin' && require_super_admin) {
      next({ name: 'Dashboard' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
