// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin';
// Validation plugin used to validate forms
import { configure } from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/SidebarPlugin';

// element ui language configuration
import { useLocale, localeContext } from 'element-plus';
import en from 'element-plus/es/locale/lang/en';

// Set the locale
const { locale } = useLocale();

// Apply the English locale
locale.value = en;

// asset imports
import '@/assets/sass/argon.scss';
import '@/assets/css/nucleo/css/nucleo.css';
import { localize } from '@vee-validate/i18n';
import { defineRule } from 'vee-validate';
import * as rules from '@vee-validate/rules';
import englishLocale from '@vee-validate/i18n/dist/locale/en.json'; // Adjust the import as necessary


// Ensure each rule is a function
Object.keys(rules).forEach((rule) => {
  if (typeof rules[rule] === 'function') {
    defineRule(rule, rules[rule]);
  }
});

configure({
  generateMessage: localize({
    en: englishLocale, // Load English messages from the imported locale
  }),
});

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      }
    })
  }
};
