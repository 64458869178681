<template>
  <router-view></router-view>
</template>

<script>
import Swal from 'sweetalert2';
import { store } from '@/vuex/store'
//Stop error resizeObserver loop
// found this on stackoverflow will remove it later after permanent fix
const debounce = (callback, delay) => {
let tid;
return function (...args) {
const ctx = self;
tid && clearTimeout(tid);
tid = setTimeout(() => {
callback.apply(ctx, args);
}, delay);
};
};

const _ = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ {
constructor(callback) {
callback = debounce(callback, 20);
super(callback);
}
};
// End of stop error resizeObserver loop
export default {
  name:'App',
  mounted() {
    if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      Swal.fire({
        title: 'Browser Error!',
        text: 'This app is not intended to be used on Internet Explorer. Please use a modern browser or contact Baritastic for assistance. ',
        icon: 'error',
        confirmButtonText: 'Close'
      })
    }
    if (store.state.sessionCheck) {
      setInterval(() => {
        store.commit('updateSessionTime', store.getters.getSessionTime + 1)
        let loginWarning = null;
        if (90 - store.state.sessionTime == 5) {
          loginWarning = Swal.fire({
            title: 'Session Expire Warning!',
            text: 'You’ll be automatically logged out in 5 minutes due to inactivity.',
            showConfirmButton: true,
            confirmButtonText: 'Stay Logged In',
            showCancelButton: true,
            cancelButtonText: 'Logout',
            allowOutsideClick: false,
            timer: 5 * 60 * 1000,
            timerProgressBar: true,
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-secondary-action',
              cancelButton: 'btn btn-primary-action'
            }
          }).then((result) => {
            if (result.isConfirmed) {
              store.commit('updateSessionTime', 0)
            }
            if (result.isDismissed) {
              store.dispatch('_logout')
                .then(response => {
                  window.location.assign('/login')
                })
            }
          });
        }
        if (store.state.sessionTime >= 90) {
          if (store.state.sessionCheck) {
            store.dispatch('_logout')
              .then(response => {
                if (loginWarning) {
                  loginWarning.close();
                }
                window.location.assign('/login')
              })
          }
          store.commit('updateSessionTime', 0)
        }
      }, 60 * 1000)
    }
  },
  computed: {
    authUser(){
      return this.$store.getters.getAuthUser;
    },
  },
  watch: {
    authUser(newVal) {
      this.updateDashboardProgramPopup()
    }
  },
  methods: {
    updateDashboardProgramPopup() {
      if (store.state.authUser && store.state.authUser.type == 'sub_user' && store.state.authUser.popup_msg1 != '' && store.state.authUser.popup_status == 1) {
        Swal.fire({
          text: store.state.authUser.popup_msg1,
          showConfirmButton: true,
          confirmButtonText: 'OK',
          confirmButtonColor: '#832C44',
          allowOutsideClick: false,
        }).then(() => {
          store.dispatch('_updateDashboardPopupStatus')
        })
      }
    }
  }
}
</script>
<style>
  .btn-success{
    background-color: #94B527 !important;
    border-color: #94B527 !important;
  }
  .swal2-timer-progress-bar{
    background: #BB2255;
  }
</style>
