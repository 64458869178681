// Parse global options
export function parseOptions(target, options) {
  for (let item in options) {
    if (typeof options[item] !== 'object' || options[item] === null) {
      target[item] = options[item]; // Set the property if it's not an object
    } else {
      if (!target[item]) target[item] = {}; // Ensure the nested target exists
      parseOptions(target[item], options[item]); // Recursively set options
    }
  }
}