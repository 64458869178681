import { ElLoading } from 'element-plus';

export default {
  install(app, options) {
    app.config.globalProperties.startLoading = () => {
      document.querySelector('#loader').classList.add('show-loader');
    };

    app.config.globalProperties.stopLoading = () => {
      const fullPageLoader = ElLoading.service({
        target: '#loader',
      });
      fullPageLoader.close();
      setTimeout(() => {
        document.querySelector('#loader').classList.remove('show-loader');
      }, 500);
    };
  }
};
