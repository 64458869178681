import Notifications from './Notifications.vue';
import { reactive } from 'vue';

const NotificationStore = {
  state: [], // here the notifications will be added
  settings: {
    overlap: false,
    verticalAlign: 'top',
    horizontalAlign: 'right',
    type: 'info',
    timeout: 5000,
    closeOnClick: true,
    showClose: true
  },
  setOptions(options) {
    this.settings = Object.assign(this.settings, options);
  },
  removeNotification(timestamp) {
    const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp);
    if (indexToDelete !== -1) {
      this.state.splice(indexToDelete, 1);
    }
  },
  addNotification(notification) {
    if (typeof notification === 'string' || notification instanceof String) {
      notification = { message: notification };
    }
    notification.timestamp = new Date();
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + this.state.length
    );
    notification = Object.assign({}, this.settings, notification);
    this.state.push(notification);
  },
  notify(notification) {
    if (Array.isArray(notification)) {
      notification.forEach(notificationInstance => {
        this.addNotification(notificationInstance);
      });
    } else {
      this.addNotification(notification);
    }
  }
};

const NotificationsPlugin = {
  install(app, options) {
    // Create a reactive store for notifications
    const notificationStore = reactive(NotificationStore);

    // Provide the notify method and notification store to the app
    app.config.globalProperties.$notify = (notification) => {
      notificationStore.notify(notification);
    };
    app.config.globalProperties.$notifications = notificationStore;

    // Register the Notifications component globally
    app.component('NotificationsPlugin', Notifications);

    // Set options if provided
    if (options) {
      notificationStore.setOptions(options);
    }
  }
};

export default NotificationsPlugin;
