import axios from "axios";

export default {
  namespaced: true,
  state: {
    base_url: process.env.VUE_APP_BASE_API_URL,
    scheduler_base_url: process.env.VUE_APP_SCHEDULER_BASE_API_URL,
    sourceSendGroupMessage: null,
    sendGroupMessage: false,
    patient: {
      user_id: '',
      pipline_add: 0,
      pipline_patient: 0,
      matrix_system: 1,
      user_join: 0,
      program_id: 0,
      insurance_coordinator_id: 0,
      program_join_date: '',
      name: '',
      nick_name: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password1: '',
      phone: '',
      dob: '',
      city: '',
      state_id: 0,
      hight: '',
      age: 0,
      sex: '',
      inital_stage: '',
      current_stage: '',
      last_used: '',
      surgery_date: '',
      non_surgery_date: '',
      type_of_surgery: '',
      last_push_notification: '',
      last_push_notification1: '',
      last_push_notification2: '',
      last_push_notification3: '',
      last_push_notification4: '',
      starting_weight: '',
      current_weight_old: '',
      current_weight: "0.00",
      target_weight_old: '',
      target_weight: "0.00",
      device_id: '',
      is_fcm: 0,
      device_name: '',
      build_version: '',
      timezone: '',
      location_id: 0,
      update_register: 0,
      nutrition: 0,
      protien: 0,
      fiber: 0,
      current_calories: '',
      last_calories: '',
      water: 0,
      currrent_week_weight: '',
      last_week_weight: '',
      last_weight: '',
      push_id: '',
      last_push: '',
      food_str: '',
      weight_loss_gain: 0,
      calorie_differential: 0,
      total_weight_lost: 0,
      food_logs1: '',
      food_logs0: 0,
      snooze_status: 1,
      snooze_date: '',
      stalled_dimiss: 1,
      newbie_item: '',
      newbie_date: '',
      user_cat_id: 0,
      checklist_deactivate: 0,
      checklist_deactivate_reason: '',
      due_date_auto_cal: 1,
      consult_date: '',
      final_chk_item_temp: 0,
      user_cat_name: "None",
      user_cat_id2: 0,
      user_cat_name2: "None",
      admin_add: 0,
      avg_hunger_val: 0,
      avg_happy_val: 0,
      avg_bowel_val: 0,
      chat_status: 1,
      opened_app: 0,
      food: 0,
      reset_key: '',
      is_invited: 0,
      mrn_number: '',
      status: 1,
      created_by: '',
    }
  },
  getters: {
    getPatient(state){
      return state.patient;
    }
  },
  actions: {
    
    /**
     * Reset Logout Time fields
     * @param commit
     * @private
     */
    _resetLogoutTimer({commit}) {
      commit('toggleSessionCheck', true, {root: true})
      commit('updateSessionTime', 0, {root: true})
    },
      /**
     * Get All Users
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _getManageUsers({state, dispatch}, {params}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/admin/manage-users`,{params})
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
    /**
     * Get User By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _getManageUsersById({state, dispatch}, {id}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/admin/manage-users/${id}`)
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
    /**
     * Update User By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _updateManageUsersById({state, dispatch}, {fd,id}) {
        return new Promise((resolve, reject) => {
          axios.put(`${state.base_url}/api/admin/manage-users/${id}`,fd)
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
      /**
     * Delete User By Id
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _deleteManageUsersById({state, dispatch}, {id}) {
        return new Promise((resolve, reject) => {
          axios.delete(`${state.base_url}/api/admin/manage-users/${id}`)
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
    /**
     * Send password reset email to patient i.e user
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _sendPasswordResetEmail({state, dispatch}, {fd}) {
        return new Promise((resolve, reject) => {
          axios.post(`${state.base_url}/api/send-password-reset-email`,fd)
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
    /**
     * Send group message
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _sendGroupMessage({state, dispatch}, {fd}) {
        return new Promise((resolve, reject) => {
          const CancelToken = axios.CancelToken;
          state.sourceSendGroupMessage = CancelToken.source();
          axios.post(`${state.scheduler_base_url}/api/admin/manage-users/send-group-message`,fd , {cancelToken: state.sourceSendGroupMessage.token})
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch((error) => {
              if (axios.isCancel(error)) {
                // Request was canceled
                resolve({});
              } else {
                // Handle other errors
                reject(error)
              }
            });
          })
      },
    /**
     * cancel group message
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
    _cancelGroupMessage({state, dispatch}) {
      state?.sourceSendGroupMessage?.cancel()
    },
    /**
     * Download patient report
     * @param context - Contains the commit and state object
     * @param payload - Payload for the API
     * @returns {Promise<unknown>}
     */
      _downloadPatientReport({state, dispatch}, {params}) {
        return new Promise((resolve, reject) => {
          axios.get(`${state.base_url}/api/admin/manage-users/patient-report/excel`,{params,responseType: "blob"})
            .then(response => {
              dispatch('_resetLogoutTimer')
              resolve(response)
            })
            .catch(error => reject(error))
        })
      },
  },
  mutations: {
    toggleSendGroupMessage(state, payload) {
      state.sendGroupMessage = payload
    },
  }
};
