<template>
 <div>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.contact == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Contact Us')"
                 :link="{ name: 'Contact Us', path: '/contacts' }"
   >
   </sidebar-item>

   <sidebar-item  v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.our_team == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Our Team')"
                  :link="{ name: 'Our Team', path: '/doctors' }"
   >
   </sidebar-item>
   <sidebar-item  v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.faq == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('FAQ’s')"
                  :link="{ name: 'FAQ\'s', path: '/faqs' }"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.vitamin_supplement == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user')  && checkUserMenuStatus('Vitamin and Supplements')"
                 :link="{name: 'Vitamin and Supplements', path: '/vitamin-supplement'}"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.after_before == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user')  && checkUserMenuStatus('Success Stories')"
                 :link="{name: 'Success Stories', path: '/success-stories'}"
   >
   </sidebar-item>
   <sidebar-item v-if="(userType == 'program_user' || userType == 'sub_user')  && checkUserMenuStatus('Diabetes Education')"
                 :link="{name: 'Diabetes Education', path: '/diabetes'}"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.videos == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user')  && checkUserMenuStatus('Videos')"
                 :link="{name: 'Videos', path: '/videos'}"
   >
   </sidebar-item>
   <sidebar-item  v-if="userType === 'sub_user'"
                  :link="{ name: 'Resources', path: '/resource-manager' }"
   >
   </sidebar-item>

   <sidebar-item  v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.diet == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Diet Guidelines')"
                  :link="{ name: 'Nutrition Guidelines', path: '/diet' }"
   >
   </sidebar-item>
   <sidebar-item  v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.instruction == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Instructions')"
                  :link="{ name: 'Instructions', path: '/instructions' }"
   >
   </sidebar-item>

   <sidebar-item  v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.non_surgeries_wight == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && programId != henryFordId && checkUserMenuStatus('Non-Surgical Weight Loss')"
                  :link="{ name: 'Non Surgical Weight Loss', path: '/non-surgical-weight-loss' }"
   >
   </sidebar-item>
   <sidebar-item  v-if="(userType == 'program_user' && (programUserWithSidebarMenuList.program_logo == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user'"
                  :link="{ name: 'Program Logo', path: '/program-logo' }"
   >
   </sidebar-item>
   <sidebar-item v-if="(userType == 'program_user' ||  userType === 'sub_user') && checkUserMenuStatus('Check My BMI')"
                 :link="{ name: 'Check My BMI', path: '/check-my-bmi' }"
   ></sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.support_services == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user')  && checkUserMenuStatus('Support Service')"
                 :link="{name: 'Support Service', path: '/support-service'}"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.support_group == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user')  && checkUserMenuStatus('Support Group')"
                 :link="{name: 'Support Group', path: '/support-group'}"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.join_us_facebook == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Join Us On Facebook')"
                 :link="{ name: 'Join Us On Facebook', path: '/join-our-facebook' }"
   >
   </sidebar-item>
   <sidebar-item v-if="userType == 'sub_user' && checkUserMenuStatus('Shop')"
                 :link="{ name: 'Shop', path: '/shop' }"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.our_website == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Our Website')"
                 :link="{ name: 'Our Website', path: '/our-website' }"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.Newsletter == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Newsletter')"
                 :link="{ name: 'Newsletter', path: '/newsletter' }"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.our_blog == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Our Blog')"
                 :link="{ name: 'Our Blog', path: '/blog' }"
   >
   </sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.patient_portal == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('Patient Portal')"
                 :link="{ name: 'Patient Portal', path: '/patient-portal' }"
   >
   </sidebar-item>
   <sidebar-item v-if="(userType == 'program_user' || userType === 'sub_user') && checkUserMenuStatus('Patient Portals')"
                 :link="{ name: 'Patient Portals', path: '/patient-portals' }"
   >
   </sidebar-item>
   <sidebar-item v-if="(userType == 'program_user' || userType === 'sub_user') && checkUserMenuStatus('Education')"
                 :link="{ name: 'Education', path: '/education' }"
   ></sidebar-item>
   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.online_seminar == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user' ) && checkUserMenuStatus('Online Seminar')"
                 :link="{ name: 'Online Seminar', path: '/online-seminar' }"
   ></sidebar-item>

   <sidebar-item v-if="((userType == 'program_user' && (programUserWithSidebarMenuList.about_surgery == 1 || programUserWithSidebarMenuList.self_service == 1)) ||  userType === 'sub_user') && checkUserMenuStatus('About Surgery')"
                 :link="{ name: 'About Surgery', path: '/about-surgery' }"
   ></sidebar-item>
   <div v-for="(section, index) in dynamicContentSections" :key="`dynamic_section_${index}`">
     <router-link class="nav-link" :to="{name: 'DynamicContentSection', params: {id: section.id, name: section.name}}">
       <span class="nav-link-text">{{ section.name }}</span>
     </router-link>
   </div>
 </div>
</template>

<script>

export default {
  props: ['userType','programUserWithSidebarMenuList','henryFordId','programId','subMenuManagers', 'dynamicContentSectionsList'],
  data() {
    return {
      dynamicContentSections: []
    };
  },
  created() {

  },
  watch: {
    dynamicContentSectionsList(newVal) {
      this.dynamicContentSections = newVal
    },
    isDynamicSectionStatusChanged(newVal) {
      if (newVal == true) {
        this.getDynamicContentSections()
      }
    }
  },
  computed: {
    isDynamicSectionStatusChanged() {
      return this.$store.state.dynamicContentSectionModule.isDynamicSectionStatusChanged
    }
  },
  methods: {
    checkUserMenuStatus(name){
      let status = false;
      let sidebarMenuListFromSubMenuManagers = this.$store.getters.getSidebarMenuListFromSubMenuManagers;
      if(sidebarMenuListFromSubMenuManagers){
        sidebarMenuListFromSubMenuManagers.filter(function(menuItem){
          if (menuItem.sub_menu.title == name && menuItem.status == 1) {
            status =  true;
          }
        });
      }
      return status;
    },

    /**
     * Get all the dynamic content sections
     */
    getDynamicContentSections() {
      let vm = this
      vm.$store.dispatch('dynamicContentSectionModule/_getDynamicContentSections')
      .then(response => {
        vm.dynamicContentSections = response.data.data
      })
      .catch(error => {
        const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Dynamic Content Sections',
          message: message
        })
      })
    }
  },
  mounted() {
    this.dynamicContentSections = this.dynamicContentSectionsList
  },

};
</script>
