<template>
  <modal-component v-loading="loader"
    class="terms-and-conditions-modal"
    :show="$store.state.modals.termsAndConditionsModal"
    @close="$store.state.modals.termsAndConditionsModal = false"
    :show-close="false"
    :self-down-close="false"
  >
    <template #header><h1 class="modal-title pt-2">Terms and Conditions</h1></template>
    <ol class="terms-and-conditions-body" @scroll="onScrollTerms">
      <h2 class="text-align-center">Baritastic Educate Platform License Agreement</h2>
      <h5 class="text-justify pt-3">
        THIS  BARITASTIC EDUCATE  PLATFORM  LICENSE (THE  “EDUCATE PLATFORM”)  AGREEMENT
        (THE “AGREEMENT”) WILL APPLY IF YOU (“LICENSEE”) DOWNLOAD, INSTALL OR USE THE EDUCATE
        PLATFORM  (AS  DEFINED  HEREIN).  PLEASE  DO  NOT  DOWNLOAD,  INSTALL  OR  USE  THE  PLATFORM
        BEFORE  YOU UNDERSTAND AND AGREE  WITH THE  TERMS AND CONDITIONS OF THIS AGREEMENT.
        THIS  AGREEMENT  IS  EFFECTIVE  UPON  LICENSEE’S  DOWNLOAD,  INSTALLATION  OR  USE  OF  THE
        PLATFORM (THE “EFFECTIVE DATE”) BETWEEN LICENSEE AND METAGENICS INC. D/B/A/ BARITASTIC
        (“BARITASTIC”).  LICENSEE  INDICATES  ITS  ACCEPTANCE  OF  THIS  AGREEMENT  BY  DOWNLOADING,
        INSTALLING  AND  USING  THE  EDUCATE  PLATFORM.  LICENSEE  AND  BARITASTIC  MAY  EACH  BE
        REFERRED TO HEREIN AS A “PARTY” AND COLLECTIVELY AS THE “PARTIES”.</h5>
      <p class="pt-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="font-weight-bold">WHEREAS,</span>
        Baritastic has developed proprietary software that assists patients and individuals in their
        bariatric journey, including the Educate Platform which allows practitioners to share educational materials
        and nutritional content directly with individual.</p>
      <p class="pt-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="font-weight-bold">WHEREAS,</span>
        Baritastic is offering the Educate Platform free of charge to practitioners in accordance with this Agreement.</p>
      <p class="py-3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
        class="font-weight-bold">NOW THEREFORE,</span>
        in consideration of the mutual promises set forth herein, it is agreed by and between Baritastic and Licensee
        as follows:</p>
      <li>
        <span class="font-weight-bold">Educate Platform.</span> The Educate Platform consists of education modules,
        online resources, key contacts, a
        calendar, a hyperlink to the Baritastic Store or Bariatric Advantage e-store and the Education Platform
        Materials as defined herein that allows Licensee to upload and share nutritional and educational content
        (the “Content”) directly with individuals. Licensee is expressly responsible for all Content and Baritastic
        hereby disclaims all liability for any Content. The Baritastic Store or Bariatric Advantage e-store hyperlink
        will allow individuals to purchase supplementals and other items directly from Baritastic or Bariatric
        Advantage. For purposes of this Agreement. “Educate Platform Materials” is defined as all materials
        developed for the Educate Platform including, without limitation, specifications, measures, reports, report
        templates, software, data, documentation, data transformations, APIs and other materials created or
        provided by Baritastic.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Free of Charge.</span> Baritastic is providing the Educate Platform and the Educate
        Platform Materials free of charge to Licensee to help Licensee’s patients in their bariatric journey.
        Nothing in this Agreement shall be construed to require either Party to refer any health care provider or
        other customer to the other Party. In no event shall anything in this Agreement be construed to be an
        inducement or payment for, or in any way dependent upon, the referral of customer or the generation of
        business from one Party to the other Party or any hospital or health care provide with which either Party
        is affiliated or does business. In fulfilling its obligations under this Agreement and enforcing this
        Agreement, each Party shall comply with all applicable laws, rules, and regulations.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Intellectual Property Ownership.</span>
        All right, title, interest, ownership rights, and intellectual property rights in and to the Educate Platform
        and Educate Platform Materials, including without limitation all trademarks, copyrights, patents, trade
        secrets, inventions and goodwill related to the foregoing as well as all derivative works, compilations, and
        collective works thereof and all related technical know-how (collectively, the “Baritastic IP”), are and shall
        remain the exclusive property of Baritastic and/or its licensors. Licensee acknowledges that any
        modifications, enhancements, or improvements to any of the Baritastic IP are derivative works of the
        Baritastic IP, and all rights in or to any such derivative works are, and shall remain, the exclusive property
        of Baritastic or its licensors. If any suggestions, modifications, enhancements or improvements made by
        Licensee to Baritastic are incorporated into any of the Baritastic IP, including any subsequent versions of
        the Educate Platform or Educate Platform Materials, Licensee hereby assigns to Baritastic all rights Licensee
        may have in and to any such suggestions, modifications, enhancements or improvements concerning the Baritastic
        IP. Licensee acknowledges that the Educate Platform and Educate Platform Materials in source code form remains
        a confidential trade secret of Baritastic. Licensee shall not take any action to jeopardize, limit or
        interfere in any manner with Baritastic’s ownership of, or rights with respect to, any of the Baritastic IP.
        Licensee warrants, represents and agrees that Licensee, its employees, agents, and/or subcontractors shall
        not: (1) modify or create derivative works of any of the Baritastic IP, or (2) reverse engineer, decompile,
        reverse translate, or in any way derive source code or trade secrets from any of the Baritastic IP.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Limited License.</span>
        Baritastic grants Licensee a limited, non-exclusive, non-sublicensable, revocable right to access and use the
        Educate Platform and Educate Platform Materials during the term solely for Licensee’s internal business
        purposes and no other use. Licensee acknowledges Baritastic’s ownership of all Baritastic IP and agrees that
        it and shall do nothing to interfere with such rights. Except for the rights expressly granted to Licensee
        hereunder, Baritastic reserves for itself all other rights in and to the Baritastic IP. Resale of the Educate
        Platform and Educate Platform Materials, or any associated services made available through the Educate
        Platform and Educate Platform Materials, by Licensee to other individuals or organizations is expressly
        prohibited.
        <br><br>
        Without limiting the generality of the foregoing, Licensee will use the Educate Platform and Educate Platform
        Materials only for the purposes set forth herein and further, Licensee expressly agrees that it does not have
        rights to:
        <br>
        <ol type="a">
          <li>
            Own title, or transfer title to the Educate Platform and/or Educate Platform Materials to a third party
          </li>
          <li>
            Distribute, sublicense or otherwise provide copies or any rights in relation to the Educate Platform
            and/or Educate Platform Materials to a third party
          </li>
          <li>
            Pledge, alienate or otherwise encumber the Educate Platform and/or Educate Platform Materials to any third
            party
          </li>
          <li>
            Used the Educate Platform and/or Educate Platform Materials to rent, lease or otherwise provide
            location-enabled telecommunication or information services, including, without limitation, data
            processing, hosting, outsourcing, service bureau or online application service offerings
          </li>
          <li>
            Modify, enhance, reverse-engineer, decompile, disassemble or create derivative works of the Educate
            Platform and/or Educate Platform Materials.
          </li>
        </ol>
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Confidentiality of Educate Platform Materials.</span>
        Licensee acknowledges Baritastic IP is owned by Baritastic or its licensors and agrees not to allow third
        parties to use, disclose, duplicate, or otherwise reproduce, directly or indirectly, the Baritastic IP in
        whole or in part, or any materials relating thereto, except as specifically authorized in this Agreement or as
        required by law. In addition, Licensee agrees to take reasonable steps to ensure that no unauthorized persons
        have access to the Baritastic IP and that all authorized persons having access to the Baritastic IP shall
        refrain from any such disclosure, duplication, or reproduction except as authorized in this Agreement or as
        required by law.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Licensee Representations and Warranties.</span>
        Licensee represents and warrants that:
        <br><br>
        <ol type="a">
          <li>
            Licensee is duly organized, validly existing and in good standing under the laws of its state of
            organization with full organizational power adequate for executing, delivering, and performing its
            obligations under this Agreement
          </li>
          <li>
            This Agreement has been duly executed and delivered by Licensee and is a legal, valid, and binding
            obligation of Licensee, enforceable against Licensee in accordance with its terms
          </li>
          <li>
            It has the requisite authority regarding the use and/or disclosure of all Content provided and/or uploaded
            to the Educate Platform
          </li>
          <li>
            The execution, delivery, and performance of this Agreement does not and will not conflict with or
            contravene any provision of the charter documents or by-laws of Licensee or any agreement, documents,
            instrument, indenture, or other obligation of Licensee
          </li>
          <li>
            Licensee will comply with all applicable federal and state laws and regulations related to Licensee
            services, products and/or its business operations.
          </li>
        </ol>
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Restrictions.</span>
        Licensee is expressly prohibited from sharing or uploading any personal information, protected health
        information or other sensitive data (the “Prohibited Content”) to the Education Platform. The Education
        Platform is not designed for such Prohibited Content and is not compliant with any regulatory or statutory
        scheme governing the use, disclosure or access such Prohibited Content. Licensee expressly acknowledges and
        affirms that it will not provide and/or upload any Prohibited Content to the Education Platform.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Indemnification.</span>
        To the extent permissible by applicable law, Licensee shall indemnify and hold harmless Baritastic, its
        affiliates, its contractors and licensors, their officers, directors, members, employees and agents, against
        any third party claims, liabilities, losses or expenses (including reasonable attorney's fees) to the extent
        directly caused by (1) Licensee’s breach of this Agreement, (2) Licensee’s or its agents’ patient care
        activities (including physicians and healthcare professionals), (3) Licensee’s uploading or sharing of Content
        and / or Prohibited Content with the Education Platform and (3) Licensee’s negligence or willful misconduct.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Limitation of Liability.</span>
        Baritastic is providing the Educate Platform free of charge. Baritastic will not be responsible for any loss
        or damage arising from Licensee’s download and use of the Educate Platform or Educate Platform Materials.
        Baritastic does not guarantee the reliability of the Educate Platform or the Educate Platform Materials.
        Baritastic makes no representation or warranty concerning the reliability of data comparison and analysis or
        the conclusions that may be drawn from the Educate Platform. Licensee understands that the use of Educate
        Platform for any purpose related to patient care cannot be controlled by Baritastic and must not be
        substituted for the professional skill and judgment of a licensed healthcare provider. Further, Licensee
        understands that Baritastic is in no way responsible for any pharmacological, medical, legal or similar
        information contained in, entered into, or used in connection with the Educate Platform, including the
        Content. Licensee acknowledges that Baritastic is not liable for any errors or interruption of the Educate
        Platform. Under no circumstances shall Licensee hold Baritastic responsible for any form of damages or loss
        suffered from, but not limited to errors, delays, loss of information, or interruptions of the Educate
        Platform. Licensee understands that the Educate Platform may be temporarily unavailable for scheduled or
        unscheduled maintenance. Baritastic and Licensee agree that Baritastic shall not under any circumstances be
        held responsible or liable for situations where the data stored or communicated through the Educate Platform
        are accessed by third parties through illegal or illicit means. Baritastic does not warrant that the Educate
        Platform will be error-free nor does Baritastic make any warranty as to the results to be obtained from the
        use of the Educate Platform.
        <br><br>
        <h5 class="text-justify">THE EDUCATE PLATFORM AND ITS COMPONENTS AND ALL EDUCATE PLATFORM MATERIALS, ARE PROVIDED AND DISTRIBUTED ON AN
          “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
          WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. BARITASTIC SHALL NOT BE LIABLE FOR ANY
          DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF USE OF THE SERVICE OR INABILITY
          TO USE THE SERVICE OR SOFTWARE OR OUT OF ANY BREACH OF ANY WARRANTY INCLUDING, WITHOUT LIMITATION, DAMAGES
          RESULTING FROM LOSS OF BUSINESS, LOSS OF DATA, AND ACTS OF GOD.</h5>
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Term and Termination.</span>
        The effective date of this Agreement is effective upon Licensee’s download of the Educate Platform (“Effective
        Date”) and shall continue in effect for one year, and automatically renew for one-year terms, unless
        terminated earlier by either Party pursuant to applicable provisions of this Agreement. Either Party may
        terminate this Agreement for any reason at any time and without notice. Licensee may terminate this Agreement
        but uninstalling or deleting its Content from the Educate Platform. Upon termination or expiration of this
        Agreement, all of Licensee’s rights to access and use of any of the Educate Platform or Educate Platform
        Materials shall immediately cease, and Licensee agrees to promptly destroy or return to Baritastic all copies
        of any Baritastic IP in Licensee’s possession.
      </li>
      <li class="pt-4">
        <span class="font-weight-bold">Miscellaneous.</span>
        <br><br>
        <ol type="a">
          <li>
            <span class="font-weight-bold">Baritastic Terms of Use and Privacy Policy.</span>
            The Baritastic Terms of Use (available here:
            <a href="https://baritastic.bariapps.com/terms-of-use/" target="_blank">Terms of Use | BariApps</a>
            ) and Privacy Policy (available here:
            <a href="https://baritastic.bariapps.com/baritastic-privacy-policy/" target="_blank">Baritastic Privacy
              Policy | BariApps</a>
            ) are hereby expressly incorporated into this Agreement. To the extent
            Licensee provides Baritastic any personal information, it will be governed exclusively by the Privacy
            Policy. In the event of a conflict between the terms and conditions of the Terms of Use, the Privacy
            Policy, this Agreement will control solely with respect to the Educate Platform.
          </li>
          <li>
            <span class="font-weight-bold">Relationship of Parties.</span>
            The Parties are separate business entities and are neither partners nor joint venture Parties, and neither
            Party has the power to obligate or bind the other Party in any manner whatsoever.
          </li>
          <li>
            <span class="font-weight-bold">Assignment.</span>
            The Parties acknowledge and agree that the rights and obligations hereunder are personal to Licensee and
            Baritastic, and shall not be assigned, mortgaged, sublicensed or otherwise transferred or encumbered by
            Licensee or Baritastic, or by operation of law. Any such assignment in contravention of the foregoing
            shall be null and void and of no force and effect.
          </li>
          <li>
            <span class="font-weight-bold">Entire Agreement; Waiver or Modification.</span>
            This Agreement contains the entire agreement of the Parties relating to the subject matter of this
            Agreement and supersedes all prior written or oral agreements or communications between the Parties. The
            terms of this Agreement cannot be waived except by an agreement in writing. There are no representations,
            warranties or covenants other than those set forth in this Agreement, which sets forth the entire
            understanding between the Parties. Baritastic may modify the terms and conditions at any time and shall
            endeavor to provide notice to Licensee.
          </li>
          <li>
            <span class="font-weight-bold">No Third Party Beneficiaries.</span>
            Except as may be otherwise specifically provided in this Agreement, no individual or firm, corporation,
            partnership, or other entity shall be a third-Party beneficiary of the representations, warranties,
            covenants, and agreements made by any Party to this Agreement. Nothing in this Agreement, whether express
            or implied, is intended to confer upon any person, other than the Parties identified herein (and their
            respective heirs, representatives, successors, and permitted assigns), any rights or remedies under, or by
            reason of, this Agreement. Nothing in this Agreement is intended to relieve or discharge any liability of
            any Party hereto or any third Party. No provision in this Agreement shall give any entity any right of
            subrogation or action against any Party hereto.
          </li>
          <li>
            <span class="font-weight-bold">Severability.</span>
            If any provision of this Agreement is held to be illegal, invalid, or unenforceable under present or
            future laws, then such provision shall be fully severable, this Agreement shall be construed and enforced
            as if such illegal, invalid, or unenforceable provision had never comprised a part of this Agreement, and
            the remaining provisions of this Agreement shall remain in full force and effect and shall not be affected
            by the illegal, invalid, or unenforceable provision or by its severance from this Agreement.
          </li>
          <li>
            <span class="font-weight-bold">Applicable Law and Venue.</span>
            This Agreement shall be governed by the laws of the State of California and the United States without
            regard to conflicts of laws provisions thereof, and without regard to the United Nations Convention on the
            International Sale of Goods or the Uniform Computer Information Transactions Act (UCITA). The jurisdiction
            and venue for actions related to the subject matter hereof shall be the California state and United States
            federal courts located in Orange County, California, and both parties irrevocably consent to such personal
            jurisdiction of such courts and waive all objections thereto.
          </li>
          <li>
            <span class="font-weight-bold">Subject Headings.</span>
            Subject headings of the sections of this Agreement are for convenience only and shall not in any way
            affect the interpretation of any section of this Agreement or the Agreement itself.
          </li>
          <li>
            <span class="font-weight-bold">Notices.</span>
            Any legal notice hereunder shall be in writing sent to the notice address set forth in the signature block
            below and shall be deemed given: (i) upon receipt if by personal delivery; (ii) upon receipt if sent by
            certified or registered U.S. mail (return receipt requested); or (iii) one day after it is sent if by next
            day delivery by a major commercial delivery service.
          </li>
        </ol>
      </li>
    </ol>
    <input type="checkbox" name="termsAcceptCheck" v-model="showTermsAccept.checkBox">
    <label class="terms-accept-label" for="termsAcceptCheck">I have read and accepted the terms and conditions</label>
    <template #footer>
    <div>
      <base-button type="secondary" class="ml-auto cancel-btn" @click="closeTermsAndConditionsModal">
        Decline
      </base-button>
      <base-button native-type="submit" type="submit" class="btn-primary-action px-5" @click="saveTermsAndConditionsCheck"
                   :disabled="!(showTermsAccept.button && showTermsAccept.checkBox)">
        Accept
      </base-button>
    </div>
    </template>
  </modal-component>
</template>

<script>
export default {
  name: "TermsAndConditionsModal",
  props: ['userId'],
  data() {
    return {
      showTermsAccept: {
        button: false,
        checkBox: false
      },
      loader: false
    }
  },
  methods: {
    /**
     * Close the Terms and Conditions Modal and logout the user
     */
    closeTermsAndConditionsModal() {
      this.loader= true
      this.$emit('closeTermsAndConditionsModal')
    },

    /**
     * Save the Terms and Conditions status
     */
    saveTermsAndConditionsCheck() {
      let vm = this
      vm.loader = true
      const payload = {
        programUserId: vm.userId
      }
      vm.$store.dispatch('_updateTermsAndConditionsCheck', payload)
        .then(() => {
          vm.$store.commit('toggleTermsAndConditionsModal', false);
          vm.$notify.success({
            title: 'Success',
            message: 'Terms And Conditions Accepted.'
          });
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Terms And Conditions',
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Check while scrolling the terms and conditions
     * @param target - Target object that contains scrollTop, clientHeight, scrollHeight
     */
    onScrollTerms ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      scrollTop ++
      if (scrollTop + clientHeight >= scrollHeight) {
        this.showTermsAccept.button = true
      }
    },

    /**
     * disable Right Click
     * @param event
     */
    disableRightClick(event){
      this.$store.state.modals.termsAndConditionsModal ? event.preventDefault() : ''
    },

    /**
     * Add the Right Click Event Listener
     */
    addRightClickListener(){
      document.addEventListener('contextmenu', this.disableRightClick)
    },

    /**
     * Remove the Right Click Event Listener
     */
    removeRightClickListener(){
      document.removeEventListener('contextmenu', this.disableRightClick)
    }

  },
  updated() {
    const termsModalState = this.$store.state.modals.termsAndConditionsModal
    termsModalState ? this.addRightClickListener() : this.removeRightClickListener()

    /**
     * Key Press Events Detection
     * @param e
     * @returns {boolean}
     */
    document.onkeydown = function (e) {
      if (termsModalState) {
        if(e.keyCode == 123) {
          return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
          return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
          return false;
        }
        if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
          return false;
        }
        if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
          return false;
        }
      }
    }
  }
}
</script>

<style scoped>
.terms-and-conditions-body {
  max-height: 350px;
  overflow-y: scroll;
  padding: 25px;
  font: normal normal normal 14px/19px Cambria,Georgia,serif;
  color: #414141;
}

.terms-and-conditions-modal :deep(.modal-header), .terms-and-conditions-modal :deep(.modal-body) {
  padding-bottom: 0;
}

.modal-title{
  font: normal normal 600 20px/27px Open Sans;
}

.terms-accept-label{
  padding-left: 8px;
  font: normal normal normal 14px/19px Open Sans;
  color: #172B4D;
}

.text-align-center{
  text-align: center;
}

.terms-and-conditions-modal :deep(.modal-content), .terms-and-conditions-modal :deep(.modal-header), .terms-and-conditions-modal :deep(.modal-body) {
  width: 800px;
}

.terms-and-conditions-modal :deep(.modal-dialog) {
  justify-content: center;
}

ol > li {
  padding-bottom: 5px;
}

.btn-primary-action[disabled]:hover {
  cursor: not-allowed;
}
</style>
