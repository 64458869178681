import moment from 'moment';

export default {
  methods: {
    formatDate: function(date) {
      return moment(date).format('MMM DD, YYYY')
    },
    /**
     * Get string of days since surgery
     * @param date
     * @returns {string|*|string}
     */
    getSurgeryDateDifferenceString(date) {
      const now = moment().startOf('day');
      try {
        const surgeryDate = moment(date);
        if (surgeryDate.isSame(now, 'day')) {
          return 'Today';
        }
        const diff = now.isAfter(surgeryDate) ? now.diff(surgeryDate, 'days') : surgeryDate.diff(now, 'days');
        const years = Math.floor(diff / 365);
        const months = Math.floor((diff % 365) / 30);
        const days = diff % 30;

        let daysSince = '';
        daysSince += years !== 0 ? `${years} ${years > 1 ? 'years' : 'year'} ` : '';
        daysSince += months !== 0 ? `${months} ${months > 1 ? 'months' : 'month'} ` : '';
        daysSince += days !== 0 ? `${days} ${days > 1 ? 'days' : 'day'}` : '';

        return daysSince.trim();
      } catch (exception) {
        return 'N/A';
      }
    },
  },
}