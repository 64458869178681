import BaseInput from '@/components/Inputs/BaseInput.vue';
import BaseSelect from '@/components/Inputs/BaseSelect.vue';
import BaseDropdown from '@/components/BaseDropdown.vue';
import Card from '@/components/Cards/Card.vue';
import Modal from '@/components/Modal.vue';
import StatsCard from '@/components/Cards/StatsCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import Editor from '@tinymce/tinymce-vue'
// import Badge from '@/components/Badge.vue';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseItemPerPage from "@/components/BaseItemPerPage";
import BaseAlert from "@/components/BaseAlert";
import BaseNav from "@/components/Navbar/BaseNav";
import BaseHeader from '@/components/BaseHeader';
import FileInput from "../components/Inputs/FileInput";
import { Field, Form, ErrorMessage } from 'vee-validate';
import BaseTimePicker from "@/components/BaseTimePicker";

import {
  ElInput,
  ElTooltip,
  ElPopover,
  ElSelect,
  ElOption,
  ElDialog,
  ElButton,
  ElTabs,
  ElTabPane,
  ElDatePicker,
  ElCheckbox,
  ElCheckboxGroup,
  ElMenu,
  ElSubMenu,
  ElForm,
  ElFooter,
  ElDrawer,
  ElBadge,
  ElTimePicker, ElTimeSelect,
  ElImage,
  ElTag,
  ElFormItem,
  ElInputNumber
} from 'element-plus';
import "./custom-rules"
import flatPicker from "vue-flatpickr-component";
/**
 * You can register global components here and use them as a plugin in your mainF Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(ElBadge.name, ElBadge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseSelect.name, BaseSelect);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseItemPerPage.name, BaseItemPerPage);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(ElInput.name, ElInput);
    Vue.component(ElSelect.name, ElSelect);
    Vue.component(ElOption.name, ElOption);
    Vue.component(ElDialog.name, ElDialog);
    Vue.component(ElButton.name, ElButton);
    Vue.component(ElCheckbox.name, ElCheckbox);
    Vue.component(ElTabs.name, ElTabs);
    Vue.component(ElTag.name, ElTag);
    Vue.component(ElTabPane.name, ElTabPane);
    Vue.component(ElDatePicker.name, ElDatePicker);
    Vue.component(ElTimePicker.name, ElTimePicker);
    Vue.component(ElTimeSelect.name, ElTimeSelect);
    Vue.component(ElCheckboxGroup.name, ElCheckboxGroup);
    Vue.component(ElMenu.name, ElMenu);
    Vue.component(ElSubMenu.name, ElSubMenu);
    Vue.component(ElForm.name, ElForm);
    Vue.component(ElFormItem.name, ElFormItem);
    Vue.component(ElDrawer.name, ElDrawer);
    Vue.component(ElInputNumber.name, ElInputNumber);
    Vue.component(FileInput.name, FileInput);
    Vue.component('vee-field', Field)
    Vue.component('vee-error-message', ErrorMessage)
    Vue.component('vee-form', Form)
    Vue.component('editor-component', Editor)
    Vue.component('flat-pickr', flatPicker)
    Vue.component(BaseTimePicker.name, BaseTimePicker)
    Vue.use(ElTooltip);
    Vue.use(ElPopover);
    Vue.use(ElImage)
  }
};
window.axios = require('axios');
export default GlobalComponents;
