<template>
  <custom-card class="card-stats" :show-footer-line="true">
    <div class="row">

      <div class="col">
        <slot>
          <h5 class="card-title title text-uppercase text-muted mb-0" v-if="title">{{ title }}</h5>
          <span class="caption" v-if="caption">{{ caption }}</span>
          <span class="h2 font-weight-bold mb-0 sub-title" v-if="subTitle">{{ subTitle }}</span>
        </slot>
      </div>

      <div class="col-auto" v-if="$slots.icon || icon">
        <slot name="">
          <div class=" text-white  ">
            <img width="" :src="icon" alt="">
          </div>
        </slot>
      </div>
    </div>

    <p class="mt-3 mb-0 text-sm">
      <slot name="footer">

      </slot>
    </p>
  </custom-card>
</template>
<script>

export default {
  name: 'stats-card',
  components: {},
  props: {
    type: {
      type: String,
      default: 'primary'
    },
    icon: String,
    title: String,
    subTitle: String,
    caption: String,
    iconClasses: [String, Array]
  }
};
</script>
<style>
.card-title {
  display: inline-block;
}
.caption {
  display: inline-block;
  color: #8898AA;
  font-size: 11px;
  margin-left: 4px!important;
}
.sub-title {
  display: block;
}
</style>
