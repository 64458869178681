<template>
  <div class="position-relative w-100">
    <flat-pickr :modelValue="modelValue" :config="config" class="form-control" :placeholder="placeholder" :class="datePickerClass" />
    <img class="position-absolute cursor-pointer custom-date-picker-icon" src="/img/icons/buttons/cross-grey.svg" @click.self="clearValue" v-if="modelValue"/>
  </div>
</template>
<script>

import moment from "moment";

export default {
  inheritAttrs: false,
  name: 'base-time-picker',
  props: {
    modelValue: {
      type: [String],
      default: '',
    },
    format: {
      type: [String],
      default: 'G:i K'
    },
    placeholder: {
      type: [String],
      default: 'Select time'
    },
    datePickerClass: {
      type: [String],
      default: 'form-control'
    }
  },
  data() {
    return {
      config: {
        altFormat: this.format,
        altInput: true,
        allowInput: true,
        dateFormat: this.format,
        enableTime: true,
        noCalendar: true,
        defaultDate: null,
        onOpen:  (selectedDates, dateStr, instance) => {
          if (!dateStr) {
            instance.setDate(new Date(), true);
          }
        },
        formatDate: (date, format, locale) => {
          return moment(date).format('hh:mm a');
        },
        onChange: (selectedDates, dateStr) => {
          this.change(dateStr);
          this.input(dateStr);
        },
        onClose: (selectedDates, dateStr) => {
          this.blur(dateStr);
        },
      }
    };
  },
  computed: {
    listeners() {
      return {
        input: this.input
      };
    },
  },
  methods: {
    input(value) {
      this.$emit('update:modelValue', value);
    },
    blur(value) {
      this.$emit('blur', value);
    },
    change(value) {
      this.$emit('change', value);
    },
    clearValue() {
      this.$emit('update:modelValue', '');
    }
  }
};
</script>

<style scoped>
.custom-date-picker-icon {
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
}
</style>
