import {parseOptions} from "@/components/Charts/optionHelpers";
import {Chart, registerables} from 'chart.js';
import 'chartjs-adapter-moment'; // Install this adapter for date handling

// Register the necessary components for Chart.js v3
Chart.register(...registerables);

export const Charts = {
  mode: 'light', //(themeMode) ? themeMode : 'light';
  fonts: {
    base: 'Open Sans'
  },
  colors: {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529'
    },
    theme: {
      'default': '#172b4d',
      'primary': '#5e72e4',
      'secondary': '#f4f5f7',
      'info': '#11cdef',
      'success': '#2dce89',
      'danger': '#f5365c',
      'warning': '#fb6340'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  }
};

function chartOptions() {
  let { colors, mode, fonts } = Charts;

  // Options
  let options = {
    responsive: true,
    maintainAspectRatio: false,
    defaultColor: (mode === 'dark') ? colors.gray[700] : colors.gray[600],
    defaultFontColor: (mode === 'dark') ? colors.gray[700] : colors.gray[600],
    defaultFontFamily: fonts.base,
    defaultFontSize: 13,
    layout: {
      padding: 0
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 16
        }
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      }
    },
    datasets: {
      point: {
        radius: 0,
        backgroundColor: colors.theme['primary']
      },
      line: {
        tension: 0.4,
        borderWidth: 4,
        borderColor: colors.theme['primary'],
        backgroundColor: colors.transparent,
        borderCapStyle: 'rounded'
      },
      bar: {
        backgroundColor: colors.theme['warning'],
        borderRadius: 5,
        barThickness: 20
      },
      arc: {
        backgroundColor: colors.theme['primary'],
        borderColor: (mode === 'dark') ? colors.gray[800] : colors.white,
        borderWidth: 4
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: (mode === 'dark') ? colors.gray[900] : colors.gray[200],
          drawBorder: false,
          drawTicks: true,
          zeroLineWidth: 1,
          zeroLineColor: (mode === 'dark') ? colors.gray[900] : colors.gray[200],
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2]
        },
        ticks: {
          padding: 10,
          callback: function (value) {
            if (!(value % 10)) {
              return value
            }
          }
        }
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
          lineWidth: 1,
          zeroLineWidth: 1,
        },
        ticks: {
          padding: 20
        },
        maxBarThickness: 10
      }
    }
  };

  // Pie chart tooltips
  options.plugins.tooltip.mode = 'point';

  // Doughnut chart configuration
  options.cutout = 83;
  options.plugins.legend = {
    ...options.plugins.legend,
    callback: function (chart) {
      let data = chart.data;
      let content = '';

      data.labels.forEach(function (label, index) {
        let bgColor = data.datasets[0].backgroundColor[index];

        content += '<span class="chart-legend-item">';
        content += '<i class="chart-legend-indicator" style="background-color: ' + bgColor + '"></i>';
        content += label;
        content += '</span>';
      });

      return content;
    }
  };

  return options;
}



let initialized = false;

export function initGlobalOptions() {
  if (initialized) {
    return;
  }
  parseOptions(Chart.defaults, chartOptions());
  initialized = true;
}

export const basicOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false
    }
  },
  responsive: true
};

export let yearGraph = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      grid: {
        tickLength: 20
      },
      ticks: {
        stepSize: 10,
        maxTicksLimit: 6,
      },
    }
  }
}

export let monthGraph = {
  responsive: true,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      type: 'time',
      time: {
        unit: 'day',
        stepSize: 1,
        displayFormats: {
          day: 'MMMM DD'
        }
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        maxTicksLimit: 5,
        autoSkip: true,
        font: {
          size: 10
        }
      }
    },
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      grid: {
        tickMarkLength: 20
      },
      ticks: {
        stepSize: 30,
        maxTicksLimit: 5,
      },
      display: true,
    }
  }
};

export let weekGraph = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      type: 'time',
      time: {
        unit: 'day',
        stepSize: 1,
        displayFormats: {
          day: 'MMMM DD'
        }
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        font: {
          size: 10
        }
      }
    },
    y: {
      beginAtZero: true,
      suggestedMin: 0,
      grid: {
        tickMarkLength: 2
      },
      ticks: {
        stepSize: 30,
        maxTicksLimit: 5,
      },
      display: true,
    }
  }
};

export let sleepGraph = {
  scales: {
    x: {
      type: 'time',
      time: {
        parser: 'YYYY-MM-DD',
        tooltipFormat: 'll',
        unit: 'day',
        stepSize: 1,
        displayFormats: {
          day: 'll'
        }
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        maxTicksLimit: 5,
        autoSkip: true,
        font: {
          size: 10
        }
      }
    },
    y: {
      beginAtZero: true,
      suggestedMin: 8,
      suggestedMax: 24,
      grid: {
        display: false
      },
      ticks: {
      },
      display: true,
    },
  }
};

export let orangeFill = {
  scales: {
    x: {
      type: 'time',
      time: {
        parser: 'YYYY-MM-DD',
        tooltipFormat: 'll',
        unit: 'day',
        stepSize: 1,
        displayFormats: {
          day: 'll'
        }
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        maxTicksLimit: 5,
        autoSkip: true,
        font: {
          size: 10
        }
      }
    },
    y: {
      beginAtZero: true
    },
  }
};

export let equalDistribution = {
  responsive: true,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'day',
        unitStepSize: 1,
        parser: 'YYYY-MM-DD',
        tooltipFormat: 'll',
        displayFormats: {
          day: 'll',
        },
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        padding: 10,
        maxTicksLimit: 5,
        autoSkip: true,
        font: {
          size: 10, // Font size for ticks
        },
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        tickMarkLength: 20, // Adjusts tick length for the y-axis grid lines
      },
      ticks: {
        suggestedMin: 0,
        stepSize: 30,
        maxTicksLimit: 5,
      },
      display: true, // Display the y-axis
    },
  },
};

export let blueChartOptions = {
  scales: {
    y: {
      grid: {
        color: Charts.colors.gray[700],
        zeroLineColor: Charts.colors.gray[700]
      }
    }
  }
};

export let lineChartOptionsBlue = {
  ...basicOptions,
  plugins: {
    tooltip: {
      backgroundColor: '#f5f5f5',
      titleFont: { color: '#333' },
      bodyFont: { color: '#666' },
      bodySpacing: 4,
      padding: 12,
      mode: 'nearest',
      intersect: false,
      position: 'nearest'
    }
  },
  responsive: true,
  scales: {
    y: {
      barPercentage: 1.6,
      grid: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.0)',
        zeroLineColor: 'transparent'
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 125,
        padding: 20,
        color: '#9e9e9e'
      }
    },
    x: {
      barPercentage: 1.6,
      grid: {
        drawBorder: false,
        color: 'rgba(29,140,248,0.1)',
        zeroLineColor: 'transparent'
      },
      ticks: {
        padding: 20,
        color: '#9e9e9e'
      }
    }
  }
};

export let barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
      mode: "nearest",
      intersect: false,
      position: "nearest",
    },
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        color: "rgba(29,140,248,0.0)",
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 50,
        suggestedMax: 125,
        padding: 20,
        color: "#9e9e9e",
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: "rgba(29,140,248,0.1)",
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        color: "#9e9e9e",
      },
    },
  },
};

export let greenChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
      mode: "nearest",
      intersect: false,
      position: "nearest",
    },
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        color: "rgba(29,140,248,0.0)",
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 50,
        suggestedMax: 125,
        padding: 20,
        color: "#9e9e9e",
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: "rgba(29,140,248,0.1)",
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        color: "#9e9e9e",
      },
    },
  },
};

export let purpleChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
      mode: "nearest",
      intersect: false,
      position: "nearest",
    },
  },
  scales: {
    y: {
      grid: {
        drawBorder: false,
        color: "rgba(225,78,202,0.1)",
        zeroLineColor: "transparent",
      },
      ticks: {
        suggestedMin: 60,
        suggestedMax: 125,
        padding: 20,
        color: "#9e9e9e",
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: "rgba(225,78,202,0.1)",
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        color: "#9e9e9e",
      },
    },
  },
};

export let orangeChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
      mode: "nearest",
      intersect: false,
      position: "nearest",
    },
  },
  scales: {
    y: {
      suggestedMin: 50,
      suggestedMax: 125,
      grid: {
        drawBorder: false,
        color: "rgba(220,53,69,0.1)",
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        color: "#9e9e9e",
      },
    },
    x: {
      grid: {
        drawBorder: false,
        color: "rgba(220,53,69,0.1)",
        zeroLineColor: "transparent",
      },
      ticks: {
        padding: 20,
        color: "#9e9e9e",
      },
    },
  },
};

export let pieChartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
    },
    legend: {
      position: "top",
      labels: {
        color: "#9e9e9e",
      },
    },
  },
};

export let doughnutChartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
    },
    legend: {
      position: "top",
      labels: {
        color: "#9e9e9e",
      },
    },
  },
};

export let radarChartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
    },
    legend: {
      position: "top",
      labels: {
        color: "#9e9e9e",
      },
    },
  },
  scales: {
    r: {
      grid: {
        color: "#ccc",
      },
      ticks: {
        backdropColor: "transparent",
        color: "#9e9e9e",
      },
    },
  },
};

export let polarAreaChartOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      backgroundColor: "#f5f5f5",
      titleFont: { color: "#333" },
      bodyFont: { color: "#666" },
      bodySpacing: 4,
      padding: 12,
    },
    legend: {
      position: "top",
      labels: {
        color: "#9e9e9e",
      },
    },
  },
};
