<template>
  <modal-component @close="handleClose" :show="isModalOpened" v-loading="loader">
    <template #header><h1 class="modal-title pt-2 pl-1">Add Dynamic Content Section</h1></template>
    <vee-form v-slot="{handleSubmit}" ref="formDynamicSectionValidator">
      <form role="form" ref="formDynamicSection" @submit.prevent="handleSubmit(onFormSubmit)">
        <div class="mt--4">
          <base-input
            class=""
            :rules="{required: true}"
            name="Name"
            label="Name*"
            v-model="dynamicSection.name"
            placeholder="Name">
          </base-input>

          <base-input label="Status">
            <el-select
              class="w-100"
              label="Status"
              v-model="dynamicSection.status">
              <el-option
                v-for="option in dropdowns.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="text-right mt-4">
          <base-button class="cancel-btn" type="secondary" @click="handleClose">Cancel</base-button>
          <base-button class="btn-primary-action pl-5 pr-5 br-4" native-type="submit" type="success">Create</base-button>
        </div>
      </form>
    </vee-form>
  </modal-component>
</template>

<script>
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddDynamicContentSectionModal",
  props: ['showModal'],
  data() {
    return {
      isModalOpened: false,
      loader: false,
      dynamicSection: {
        name: '',
        status: 1,
      },
      dropdowns: {
        status: [
          {
            value: 1,
            label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    }
  },
  watch: {
    showModal(newVal) {
      this.isModalOpened = newVal
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Hide modal event
     */
    hideModal() {
      this.$emit('hideModal')
    },

    /**
     * Handle form submit event and validate form
     * @returns {Promise<void>}
     */
    async onFormSubmit() {
      const isDynamicSectionFormValid = await this.$refs['formDynamicSectionValidator'].validate()
      if (isDynamicSectionFormValid) {
        this.createDynamicSection()
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Create dynamic section
     */
    createDynamicSection() {
      let vm = this
      vm.loader = true
      vm.$store.dispatch('dynamicContentSectionModule/_createDynamicSection', vm.dynamicSection)
        .then(response => {
          this.handleClose()
          this.$emit('fetchDynamicSection')
          vm.$notify.success({
            title: 'Dynamic Section',
            message: 'Dynamic section added successfully'
          })
          vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionAdded', true)
        })
        .catch(error => {
          const message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Add Dynamic Section',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('dynamicContentSectionModule/setIsDynamicSectionAdded', false)
          vm.loader = false
        })
    },

    /**
     * Handle close event
     */
    handleClose() {
      this.resetForm()
      this.hideModal()
    },

    /**
     * Reset Form fields
     */
    resetForm() {
      this.dynamicSection.name = ''
      this.dynamicSection.status = 1
      this.$refs.formDynamicSectionValidator.resetForm()
    },
  }
}
</script>

<style scoped>

</style>
